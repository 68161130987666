/* eslint-disable camelcase,no-console,no-undef */
import { toast } from 'react-toastify';
import moment from "moment";
import { TimeZone } from '../../../utils/constants';

import { http } from '../../http';
import * as actionTypes from '../actionTypes';
import {CompanyDepartmentAPI, CompanyLocationAPI, CompanyLogin, UserRegister, GetWellnessInterests, GetFitnessGoals} from '../../constants/apiConstants';
import {authenticatedUser} from "../authActions";
import { isNull } from "lodash";

const companyLoginRequest = (company_name) => (dispatch) => dispatch({ type: actionTypes.COMPANY_LOGIN_ATTEMPT, company_name });

const companyLoginError = (error) => (dispatch) => dispatch({ type: actionTypes.COMPANY_LOGIN_FAILED, companyError: error });

const companyLoginSuccess = (response) => (dispatch) => dispatch({ type: actionTypes.COMPANY_LOGIN_SUCCESSFUL, response });

const networkError = (error) => (dispatch) => dispatch({type: actionTypes.NETWORK_ERROR, networkError: error});

const showDailyTipPopup = () =>(dispatch) => dispatch({type: actionTypes.SHOW_DAILY_TIP_POPUP});

const setSpouseDependentInfo = (bool, selectedUser) => (dispatch) => dispatch({type: actionTypes.SET_SPOUSE_DEPENDENT_INFO, user_type: bool, selectedOption: selectedUser});
const getSpouseDependentInfo = () => (dispatch) => dispatch({type: actionTypes.GET_SPOUSE_DEPENDENT_INFO});
const getSpouseDependentAtCompanyLogin = () => (dispatch) => dispatch({type: actionTypes.GET_SPOUSE_DEPENDENT_AT_COMPANY_LOGIN});
const companyLogin = ({companyName, companyPassword}, history, changeSubmit) =>
  async (dispatch) => {
    dispatch(companyLoginRequest(companyName));
    try {
      const res = await http.post(CompanyLogin, { company_name: companyName, password: companyPassword });
      if(res.message) {
        dispatch(companyLoginError(res.message));
        changeSubmit('company');
      } else {
        console.log("actual res",res)
        dispatch(companyLoginSuccess(res));
        dispatch(getCompanyLocation(res[0].id));
        dispatch(getCompanyDepartment(res[0].id));
        dispatch({type: actionTypes.SAVE_COMPANY_ID, companyId: res[0].id});
        dispatch({type: actionTypes.FETCH_SPOUSE_DEPENDENT, isSpouseDependent: res[0]['spouse_or_dependent']});
        dispatch({type: actionTypes.FETCH_SPOUSE, isSpouse: res[0]['show_spouse']});
        dispatch({type: actionTypes.FETCH_DEPENDENT, isDependent: res[0]['show_dependent']});
        history.push('/user/step1');
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  };

const ssoCompanyLogin = (res,history, /*changeSubmit*/) =>
  async (dispatch) => {
    dispatch(companyLoginRequest(res.company_name));
    console.log("res",res)
    try {

      if(!res.id) {
        // console.log("if",res)
        dispatch(companyLoginError(res.message));
        // changeSubmit('company');
      } else {
        // console.log("else history",history)
        dispatch(companyLoginSuccess([res]));
        dispatch(getCompanyLocation(res?.id));
        dispatch(getCompanyDepartment(res?.id));
        dispatch({type: actionTypes.SAVE_COMPANY_ID, companyId: res.id});
        dispatch({type: actionTypes.FETCH_SPOUSE_DEPENDENT, isSpouseDependent: res['spouse_or_dependent']});
        dispatch({type: actionTypes.FETCH_SPOUSE, isSpouse: res['show_spouse']});
        dispatch({type: actionTypes.FETCH_DEPENDENT, isDependent: res['show_dependent']});
        dispatch(saveUserInformation('email', res?.user?.email));
        dispatch(saveUserInformation('confirmEmail', res?.user?.email));
        dispatch(saveUserInformation('firstName', res?.user?.first_name));
        dispatch(saveUserInformation('lastName', res?.user?.last_name));
        history.push('/user/step1');
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  };

const getCompanyLocation = (companyId, bool) => (
  async (dispatch) => {
    try {
      const res = await http.get(`${CompanyLocationAPI}?company_id=${isNull(companyId)?1:companyId}`);
      if(res.message) {
        dispatch(companyLoginError(res.message));
      }
      else {
        if(!bool) {
          if(!isNull(res) && res.length > 0){
            dispatch({ type: actionTypes.SET_COMPANY_LOCATION_NOT_NULL})
          } else {
            dispatch({ type: actionTypes.SET_COMPANY_LOCATION_NULL})
          }
          dispatch({ type: actionTypes.SET_COMPANY_LOCATION, locations: res});
        }
        else {
          let data = [{ id: null, location: 'All Locations' }];
          dispatch({ type: actionTypes.SET_PEOPLE_COMPANY_LOCATION, locations: data.concat(res)});
        }
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
)

const getCompanyDepartment = (companyId, bool) => (
  async (dispatch) => {
    try {
      const res = await http.get(`${CompanyDepartmentAPI}?company_id=${isNull(companyId)?1:companyId}`);
      if(res.message) {
        dispatch(companyLoginError(res.message));
      }
      else {
        if(!bool) {
          if(!isNull(res) && res.length > 0) {
            dispatch({ type: actionTypes.SET_COMPANY_DEPARTMENT_NOT_NULL})
          } else {
            dispatch({ type: actionTypes.SET_COMPANY_DEPARTMENT_NULL})
          }
          dispatch({ type: actionTypes.SET_COMPANY_DEPARTMENT, department: res});
        }
        else {
          let data = [{ id: null, department: 'All Department' }];
          dispatch({ type: actionTypes.SET_PEOPLE_COMPANY_DEPARTMENT, res: data.concat(res)});
        }
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
)

const getCompanyId = () => (dispatch) => dispatch({type: actionTypes.GET_COMPANY_ID});

const getWellnessInterests = () =>
  async (dispatch) => {
    try {
      const res = await http.get(GetWellnessInterests);
      if(res.message) {
        dispatch({type: actionTypes.FAILED_GET_WELLNESS_INTERESTS, interestError: res.message});
      } else {
        dispatch({type: actionTypes.GET_WELLNESS_INTERESTS, wellnessInterests: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  };

const getFitnessGoals = () =>
  async (dispatch) => {
    try {
      const res = await http.get(GetFitnessGoals);
      if(res.message) {
        dispatch({type: actionTypes.FAILED_GET_FITNESS_GOALS, fitnessError: res.message});
      } else {
        dispatch({type: actionTypes.GET_FITNESS_GOALS, fitnessGoals: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  };

const getUserInformation = () => (dispatch) => dispatch({type: actionTypes.GET_USER_INFORMATION});

const saveUserInformation = (name, value) => (dispatch) => dispatch({type: actionTypes.SAVE_USER_INFORMATION, name: name, value: value});
const clearUserInformation = () => (dispatch) => dispatch({type: actionTypes.CLEAR_USER_INFORMATION})

const getAreaOfInterests = () => (dispatch) => dispatch({type: actionTypes.GET_AREA_OF_INTERESTS});

const addAreaOfInterests = (area) => (dispatch) => dispatch({type: actionTypes.ADD_AREA_OF_INTERESTS, area});

const getLongTermGoals = () => (dispatch) => dispatch({type: actionTypes.GET_LONG_TERM_GOALS});

const addLongTermGoals = (goal) => (dispatch) => dispatch({type: actionTypes.ADD_LONG_TERM_GOALS, goal});

const getGeneralHealthData = () => (dispatch) => dispatch({type: actionTypes.GET_GENERAL_HEALTH_DATA});

const addGeneralHeathData = (name, value) => (dispatch) => dispatch({type: actionTypes.ADD_GENERAL_HEALTH_DATA, name, value});
const clearGeneralHealthData = () => (dispatch) => dispatch({type: actionTypes.CLEAR_GENERAL_HEALTH_DATA});
const resetLocationDepartment = () => (dispatch) => dispatch({type: actionTypes.RESET_LOCATION_DEPARTMENT});
const signUpRequest = () => (dispatch) => dispatch({ type: actionTypes.SIGNUP_ATTEMPT });

const signUpError = (error) => (dispatch) => dispatch({ type: actionTypes.SIGNUP_FAILED, error });

const signUpSuccess = (response) => (dispatch) => dispatch({ type: actionTypes.SIGNUP_SUCCESSFULLY, response });

const signUp = (history) =>
  async (dispatch, getState) => {
    const userData = getState().register.userData;
    let RegisterData = {
      company_id: getState().register.companyId,
      company_location: userData.companyLocation,
      fname: userData.firstName,
      lname: userData.lastName,
      birthday: moment(userData.birthday).format('YYYY-MM-DD'),
      email: userData.email,
      password: userData.password,
      aoi: getState().register.aoi,
      long_term: getState().register.long_term,
      exercise_day_per_week: getState().register.exercise_day_per_week,
      smoke: getState().register.smoke,
      average_sleep_per_night: getState().register.average_sleep,
      average_water_intake: getState().register.average_water_intake,
      pain_experience: getState().register.pain_experience,
      prescription_intake: getState().register.prescription_intake,
      physical_exam_frequency: getState().register.physical_exam_frequency,
      time_zone: TimeZone,
      city_state_id: userData['location'],
      department_id: userData['department'],
      accepted_privacy_policy: 1,
      user_type: getState().register.user_type,
      token: getState().auth.userEmailToken,
      profile_image:userData.profile,
      mobile_number:userData.phoneNumber

    };
    dispatch(signUpRequest());
    try {
      const res = await http.post(UserRegister, RegisterData);
      if(res.message) {
        toast.error(res.message);
        history.push('/user/step1');
        dispatch(signUpError(res.message));
        dispatch({ type: actionTypes.SIGNUP_BUTTON_STATUS, bool: false});
      } else {
        dispatch(signUpSuccess(res));
        dispatch({ type: actionTypes.SET_COMPANY_LOCATION, locations: null});
        dispatch({ type: actionTypes.SET_COMPANY_DEPARTMENT, department: null});
        dispatch(WelcomeBoxAction(true));
        localStorage.setItem('new_user', 'true');
        localStorage.setItem('AUTH_TOKEN', res.checksum);
        dispatch(authenticatedUser());
        dispatch({ type: actionTypes.SIGNUP_BUTTON_STATUS, bool: false});
        history.push('/portal');
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  };

const WelcomeBoxAction = (bool) => (dispatch) => dispatch({ type: actionTypes.SHOW_CLOSE_WELCOME_BOX , data: bool }); 

const handleSignUpButtonStatus = (bool) => (dispatch) => dispatch({ type: actionTypes.SIGNUP_BUTTON_STATUS, bool: bool });

export { showDailyTipPopup, getCompanyLocation, getCompanyDepartment, companyLogin, getUserInformation, getAreaOfInterests, addAreaOfInterests, getLongTermGoals, addLongTermGoals, signUp,
  signUpError, getGeneralHealthData, addGeneralHeathData, getWellnessInterests, getFitnessGoals, saveUserInformation, getCompanyId, WelcomeBoxAction, setSpouseDependentInfo, getSpouseDependentInfo, getSpouseDependentAtCompanyLogin, clearUserInformation, clearGeneralHealthData, resetLocationDepartment, handleSignUpButtonStatus, ssoCompanyLogin };
